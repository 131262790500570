<template>
  <ul v-if="items">
    <template v-for="(item,i) of items">
      <li v-if="visible(item) && !item.separator" :key="i"
          :ref="item.key"
          :class="[{'active-menuitem': activeIndex === i && !item.to && !(item.disabled || (authorities.includes(item.access) || !item.access)) }]" role="none">
        <div v-if="item.items && root===true" class='arrow'></div>
        <router-link v-if="item.to" v-ripple
                     :class="[item.class, 'p-ripple',{'active-route': activeIndex === i, 'p-disabled': (item.disabled || !(authorities.includes(item.access) || !item.access))}]"
                     :style="item.style"
                     :target="item.target" :to="item.to" exact role="menuitem" @click="onMenuItemClick($event,item,i)">
          <i :class="item.icon"></i>
          <span>{{ _t(item.label) }}</span>
          <i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </router-link>
        <a v-if="!item.to" v-ripple :class="[item.class, 'p-ripple', {'p-disabled': (item.disabled || !(authorities.includes(item.access) || !item.access))}]"
           :href="item.url||'#'"
           :style="item.style" :target="item.target" role="menuitem" @click="onMenuItemClick($event,item,i)">
          <i :class="item.icon"></i>
          <span>{{ label(_t(item.label)) }} <i class="hint">{{ hint(_t(item.label)) }}</i></span>
          <i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </a>
        <transition name="layout-submenu-wrapper">
          <AppSubmenu v-show="activeIndex.includes(i)" :items="visible(item) && item.items"
                      @menuitem-click="$emit('menuitem-click', $event)"></AppSubmenu>
        </transition>
      </li>
      <li v-if="visible(item) && item.separator" :key="'separator' + i" :style="item.style" class="p-menu-separator"
          role="separator"></li>
    </template>
  </ul>
</template>
<script>
export default {
  name: 'appsubmenu',
  props: {
    items: Array,
    root: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: [],
      authorities: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).authorities : []
    }
  },
  methods: {
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({originalEvent: event, item: item});
      }

      if (this.activeIndex.includes(index)) {
        this.activeIndex.splice(this.activeIndex.indexOf(index), 1)
      } else {
        this.activeIndex.push(index)
      }

      this.$emit('menuitem-click', {
        originalEvent: event,
        item: item
      });
    },
    visible(item) {
      return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
    },
    label(text) {
      return text.replace(/\(.+\)/, '')
    },
    hint(text) {
      return text.replace(this.label(text), '')
    }
  },
  watch: {
    '$route.name'(name) {
      if(name !== 'alarm-panels-list') {
        localStorage.removeItem('panelsPath');
      }
    }
  }
}
</script>

<style scoped>
.hint {
  font-size: 10px;
}
</style>
