<template>
  <div class="layout-profile">
    <!--		<div>-->
    <!--			<img src="assets/layout/images/avatar.png" alt="" />-->
    <!--		</div>-->
    <button v-if="user" class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ user.name }} {{ user.surname }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link" @click="$router.push('/account')"><i class="pi pi-fw pi-user"></i><span>{{_t('label_user_account')}}</span>
          </button>
        </li>
        <li>
          <button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>{{_t('label_logout')}}</span></button>
        </li>
      </ul>

    </transition>
  </div>
</template>

<script>
// import router from "@/router";

import {logout} from "./api/auth";
import {deleteTableFilters} from "@/api/tableFilters";

export default {
  data() {
    return {
      expanded: false,
      user: {}
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    logout() {
      logout().then(() => {
        deleteTableFilters()
        localStorage.removeItem('authtoken')
        localStorage.removeItem('orgId')
        localStorage.removeItem('orgData')
        localStorage.removeItem('user')
        localStorage.removeItem('multiOrgData')
        localStorage.removeItem('multiOrgIds')
        localStorage.removeItem('af.global.groupFilter')
        localStorage.removeItem('af.my.participantsFilter')
        localStorage.removeItem('af.history.participantsFilter')
        localStorage.removeItem('af.all.participantsFilter')
        localStorage.removeItem('af.my.participantsFilter')
        localStorage.removeItem('af.all.alarmFilters')
        localStorage.removeItem('af.all.durationFilters')
        localStorage.removeItem('af.my.alarmFilters')
        localStorage.removeItem('af.my.durationFilters')
        localStorage.removeItem('af.history.alarmFilters')
        localStorage.removeItem('af.history.durationFilters')
        window.location.href = '/'
      })
    }
  }
}
</script>
