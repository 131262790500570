import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/dashboard/index.vue'),
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('./views/account/index'),
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue'),
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue'),
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue'),
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue'),
    },
    {
        path: '/user-groups/:id?',
        name: 'user-groups-list',
        component: () => import('./views/user_group/index'),
    },
    {
        path: '/users/:id?',
        name: 'users-list',
        component: () => import('./views/user/index'),
    },
    {
        path: '/participants/:id?',
        name: 'participants-list',
        component: () => import('./views/participant/index'),
    },
    {
        path: '/participant-groups/:id?',
        name: 'participant-groups-list',
        component: () => import('./views/participant_group/index'),
    },
    {
        path: '/organizations/:id?',
        name: 'organizations-list',
        component: () => import('./views/organization/index'),
    },
    {
        path: '/alarm-points/:id?',
        name: 'alarm-points-list',
        component: () => import('./views/alarm_point/index'),
    },
    {
        path: '/deleted-alarm-points/:id?',
        name: 'deleted-alarm-points-list',
        component: () => import('./views/alarm_point/index'),
    },
    {
        path: '/alarm-trigger',
        name: 'alarm-trigger',
        component: () => import('./views/alarm_trigger/index'),
    },
    {
        path: '/standby-schedules',
        name: 'standby-schedules',
        component: () => import('./views/standby_schedules/index'),
    },
    {
        path: '/team-schedules',
        name: 'team-schedules',
        component: () => import('./views/team_media_schedules/index'),
    },
    {
        path: '/private-schedules',
        name: 'private-schedules',
        component: () => import('./views/private_media_schedules/index'),
    },
    {
        path: '/holidays',
        name: 'holidays',
        component: () => import('./views/holidays/index'),
    },
    {
        path: '/alarm-plans/:id?',
        name: 'alarm-plans-list',
        component: () => import('./views/alarm_plan/index'),
    },
    {
        path: '/alarm-panels',
        name: 'alarm-panels-list',
        component: () => import('./views/alarm_panel/index'),
    },
    // {
    //     path: '/alarm-panels-instant',
    //     name: 'alarm-panels-instant-list',
    //     component: () => import('./views/alarm_panel_instant/index'),
    // },
    {
        path: '/alarm-table',
        name: 'alarm-table-list',
        component: () => import('./views/alarm_table/index'),
    },
    {
        path: '/alarm-rules/:id?',
        name: 'alarm-rules',
        component: () => import('./views/alarm_rule/index'),
    },
    {
        path: '/alarm-templates',
        name: 'alarm-templates',
        component: () => import('./views/alarm_templates/index'),
    },
    {
        path: '/text-templates',
        name: 'text-templates',
        component: () => import('./views/text_templates/index'),
    },
    {
        path: '/reports/:tab',
        name: 'reports',
        component: () => import('./views/reports/index'),
    },
    {
        path: '/attachments',
        name: 'attachments',
        component: () => import('./views/attachments/index'),
    },
    {
        path: '/alarm-apps',
        name: 'apps',
        component: () => import('./views/apps/index'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/auth/login'),
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test/test'),
    },
    {
        path: '/views',
        name: 'views',
        component: () => import('@/views/system_tables/index'),
    },
    {
        path: '/import-export',
        name: 'import-export',
        component: () => import('@/views/import_export/index'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
