export const ListTypeEnum = Object.freeze({
    HOLIDAY: 'HOLIDAY',
    USER: 'USER',
    USERGROUP: 'USERGROUP',
    ORGANIZATION: 'ORGANIZATION',
    PARTICIPANT: 'PARTICIPANT',
    PARTICIPANT_GROUP: 'PARTICIPANT_GROUP',
    ALARMPLAN: 'ALARMPLAN',
    ALARMPOINT: 'ALARMPOINT',
    ALARM_ROTATIONAL: 'ALARM_ROTATIONAL',
    PARTICIPANT_ROTATIONAL: 'PARTICIPANT_ROTATIONAL',
    ALARM_RULE: 'ALARM_RULE',
    ALARM_TEMPLATE: 'ALARM_TEMPLATE',
})
