import request from "@/api/request";

export const createApp = data => {
    return request({
        url: `/alarmpoints/predefined`,
        method: 'POST',
        data,
    })
}

export const getAppByOrgId = (orgId, appType) => {
    return request({
        url: `/alarmpoints/predefined/${orgId}?alarmtype=${appType}`,
        method: 'GET',
    })
}

export const removeAppById = appId => {
    return request({
        url: `/alarmpoints/predefined/${appId}`,
        method: 'DELETE',
    })
}

export const updateApp = app => {
    return request({
        url: `/alarmpoints/predefined`,
        method: 'PUT',
        data: app
    })
}
