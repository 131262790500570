<template>
  <Accordion>
    <AccordionTab>
      <template #header>
        <span style="padding-left: 10px;">
        <span>Search</span>
        </span>
      </template>
      <span style="min-width: 25rem;">
    <span class="p-field p-mt-5">
    <label for="name"
           style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">{{
        _t('label_name')
      }}</label>
      <div class="p-pb-0"/>
     <span class="p-input-icon-right">
            <i class="pi pi-search"/>
            <InputText id="name" v-model="participantMultiFilter.name" style="width: 24rem;" type="text"/>

     </span>
          <div class="p-pb-3"/>
    </span>
        <span class="p-field p-mt-5">
    <label for="surname"
           style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">{{
        _t('label_surname')
      }}</label>
      <div class="p-pb-0"/>
     <span class="p-input-icon-right">
            <i class="pi pi-search"/>
            <InputText id="surname" v-model="participantMultiFilter.surname" style="width: 24rem;" type="text"/>

     </span>
    </span>
        <div class="p-pb-3"/>
    <span class="p-field p-mt-5">
    <label for="shortname"
           style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">{{
        _t('label_short_name')
      }}</label>
      <div class="p-pb-0"/>
     <span class="p-input-icon-right">
            <i class="pi pi-search"/>
            <InputText id="shortname" v-model="participantMultiFilter.shortname" style="width: 24rem;" type="text"/>

     </span>
              <div class="p-pb-3"/>
    </span>
    <span class="p-field p-mt-5">
    <label for="lang"
           style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">
      {{
        _t('label_language')
      }}
    </label>
            <div class="p-pb-0"/>
       <Dropdown id="lang" v-model="participantMultiFilter.language"
                 :options="availableLanguages"
                 :placeholder="_t('label_language')"
                 :showClear="true"
                 optionLabel="label"
                 optionValue="value"
                 style="width: 24rem;"
       />
    </span>
    <div class="p-pb-3"/>
    <span class="p-field p-mt-5">
      <label for="device"
             style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;"
      >
        {{
          _t('DEVICE_GROUP')
        }}
      </label>
            <div class="p-pb-0"/>
      <Dropdown
          id="device"
          v-model="participantMultiFilter.device"
          :options="getDevices"
          :placeholder="_t('_NONE')"
          :showClear="true"
          class="p-p-0"
          dataKey="message"
          optionLabel="message"
          optionValue="callingDeviceType"
          style="width: 24rem;"
      />
    </span>
        </span>
      <div class="p-pb-3"/>
      <hr>
      <span class="p-field p-mt-5">
    <label for="group_name"
           style="color: #6c757d; margin-top: 10px; margin-bottom: 1px; margin-left: 5px;">{{
        _t('label_pg')
      }}</label>
      <div class="p-pb-0"/>
     <span class="p-input-icon-right">
            <i class="pi pi-search"/>
            <InputText id="shortname" v-model="participantMultiFilter.groupname" style="width: 24rem;" type="text"/>

     </span>
    </span>
    </AccordionTab>
  </Accordion>

  <div class="p-pb-3"/>
  <Tree
      ref="tree"
      v-model:selectionKeys="selectedItems"
      :filter="false"
      :filterPlaceholder="_t('filter_org_filter')"
      :loading="loading"
      :metaKeySelection="false"
      :placeholder="_t('Select')"
      :value="isRecursive ? organizations[0].children : organizations"
      filter-by="label"
      filterMode="lenient"
      selectionMode="checkbox"
      style="min-width: 24rem;"
      @nodeExpand="nodeExpand"
      @nodeSelect="nodeSelect"
      @nodeUnselect="nodeUnselect"
      @onCompositionEnd="console.log($event)"
  >
    <template #organization="slotProps">
      <b>{{ slotProps.node.label }}</b>
    </template>
    <template #participant="slotProps">
      {{ slotProps.node.label }}
    </template>
    <template #group="slotProps">
      <span class="color-green">{{ slotProps.node.label }}</span>
    </template>
  </Tree>
</template>

<script>
import {getList, getListExtended} from '@/api/list'
//import {getOrg} from  '@/api/organization'
import {getTree} from "@/api/tree"
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ParticipantSelector',
  props: {
    modelValue: {},
    orgId: {
      type: [Number, String],
      default: null
    },
    allowParticipants: {
      type: Boolean,
      default: true
    },
    allowParticipantGroups: {
      type: Boolean,
      default: true
    },
    groupIdField: {
      type: String,
      default: 'planGroupId'
    },
    groupId: {
      type: [Number, String]
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedItems: [],
      organizations: [],
      participants: [],
      participantGroups: [],
      fetched: {},
      loadingOrgs: false,
      loadingParticipants: false,
      loadingGroups: false,
      filterValue: '',
      participantMultiFilter: {
        name: '',
        device: '',
        surname: '',
        shortname: '',
        groupname: ''
      },
      isRecursive: false,
      availableLanguages: []
    }
  },
  mounted() {
    this.initRootOrg()
    this.initSelected()
    this.callGetDevices()
    this.availableLanguages = [
      {value: 'ENGLISH', label: this._t('ENGLISH')},
      {value: 'GERMAN', label: this._t('GERMAN')},
      // {value: 'FRENCH', label: this._t('FRENCH')},
      {value: 'ITALIAN', label: this._t('ITALIAN')},
      {value: 'CZECH', label: this._t('CZECH')},
      // {value: 'GREEK', label: this._t('GREEK')},
      // {value: 'SPANISH', label: this._t('SPANISH')}
    ];
  },
  computed: {
    loading() {
      let result = this.loadingOrgs
      if (this.allowParticipants) {
        result = result && this.loadingParticipants
      }
      if (this.allowParticipantGroups) {
        result = result && this.loadingGroups
      }

      return result
    },
    ...mapGetters({
      'getDevices': 'devices/getDevices',
    }),
  },
  watch: {
    'participantMultiFilter.name': function () {
      this.initSelected()
      this.checkForRecursive()
    },
    'participantMultiFilter.surname': function () {
      this.initSelected()
      this.checkForRecursive()
    },
    'participantMultiFilter.shortname': function () {
      this.initSelected()
      this.checkForRecursive()
    },
    'participantMultiFilter.groupname': function () {
      this.initSelected()
      this.checkForRecursive()
    },
    'participantMultiFilter.device': function () {
      this.initSelected()
      this.checkForRecursive()

    },
    'participantMultiFilter.language': function () {
      this.initSelected()
      this.checkForRecursive()
    },
  },
  methods: {
    ...mapActions({
      'callGetDevices': 'devices/callGetDevices',
    }),
    checkForRecursive() {
      this.isRecursive =
          this.participantMultiFilter.surname.length > 0
          || this.participantMultiFilter.shortname.length > 0
          || this.participantMultiFilter.name.length > 0
          || this.participantMultiFilter.groupname.length > 0
          || this.participantMultiFilter.language?.length > 0
          || this.participantMultiFilter.device?.length > 0;
      this.initRootOrg()
    },
    loadOrg(org) {
      if (!this.isRecursive) {
        this.loadOrganizations(org)
      }
      if (this.allowParticipants) {
        this.loadParticipants(org)
      }
      if (this.allowParticipantGroups) {
        this.loadParticipantGroups(org)
      }

      org.leaf = true
    },
    loadOrganizations(org) {
      this.loadingOrgs = true
      getTree(org.data.id).then((response) => {
        let organizations = response.data
        org.children = org.children.concat(organizations.map((item) => {
          this.fetched['o' + item.id] = item
          return {
            key: 'o' + item.id,
            label: item.name,
            leaf: false,
            children: [],
            icon: 'pi pi-sitemap',
            data: item,
            selectable: false,
            weight: 100,
            type: 'organization'
          }
        }, this))

        org.children.sort(this.sort)

        let manualCallsOrgPos = org.children.findIndex(item => item.label.toLowerCase() == 'manual calls')
        if (manualCallsOrgPos !== -1) {
          let manualCallsOrg = org.children.splice(manualCallsOrgPos, 1)
          let firstOrgPos = org.children.findIndex(item => item.key.startsWith('o'))
          firstOrgPos = firstOrgPos === -1 ? 0 : firstOrgPos
          org.children.splice(firstOrgPos, 0, manualCallsOrg[0])
        }

        this.$forceUpdate()
      }).catch((error) => {
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage('Unable to get participants' + (data.reason ? ': ' + data.reason : ''), 'error')
      }).then(() => {
        this.loadingOrgs = false
      })
    },
    loadParticipants(org) {
      this.loadingParticipants = true
      if (this.participantMultiFilter.device === '') {
        delete this.participantMultiFilter.device
      }

      let isParticipantFilterActive = this.participantMultiFilter.surname.length > 0
          || this.participantMultiFilter.shortname.length > 0
          || this.participantMultiFilter.name.length > 0
          || this.participantMultiFilter.language?.length > 0
          || this.participantMultiFilter.device?.length > 0;

      if (this.participantMultiFilter.groupname.length === 0 || isParticipantFilterActive) {
        getListExtended({"participantMultiFilter": this.participantMultiFilter}, 'PARTICIPANT', 0, this.filterValue, 10000, org.data.id, this.isRecursive).then((response) => {
          org.children = org.children.concat(response.data.list.map((item) => {
            this.fetched['p' + item.id] = item
            return {
              key: 'p' + item.id,
              label: `${item.name} ${item.surname} ${item.shortName ? `(${item.shortName})` : ''}`,
              leaf: true,
              icon: 'pi pi-user',
              data: item,
              selectable: true,
              weight: 10,
              type: 'participant'
            }
          }, this))
          org.children.sort(this.sort)
          this.$forceUpdate()
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage('Unable to get participants' + (data.reason ? ': ' + data.reason : ''), 'error')
        }).then(() => {
          this.loadingParticipants = false
        })
      }

    },
    loadParticipantGroups(org) {
      this.loadingGroups = true
      if (
          (this.participantMultiFilter.groupname.length > 0) || !this.isRecursive
      ) {
        getList('PARTICIPANT_GROUP', 0, this.participantMultiFilter.groupname, 10000, org.data.id, this.isRecursive).then((response) => {
          org.children = org.children.concat(response.data.list.map((item) => {
            this.fetched['g' + item.id] = item
            return {
              key: 'g' + item.id,
              label: item.name,
              leaf: true,
              icon: 'pi pi-users',
              data: item,
              id: item.id,
              selectable: true,
              weight: 50,
              type: 'group'
            }
          }, this))
          org.children.sort(this.sort)
          this.$forceUpdate()
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage('Unable to get participants' + (data.reason ? ': ' + data.reason : ''), 'error')
        }).then(() => {
          this.loadingGroups = false
        })
      }

      //   getListExtended({
      //     "participantGroupMultiFilter": {
      //       members: [{
      //         callingDeviceTypes: this.participantMultiFilter.device,
      //         language: this.participantMultiFilter.language
      //       }],
      //       name: this.participantMultiFilter.groupname
      //     }
      //   }, 'PARTICIPANT_GROUP', 0, '', 10000, org.data.id, this.isRecursive).then((response) => {
      //     org.children = org.children.concat(response.data.list.map((item) => {
      //       this.fetched['g' + item.id] = item
      //       return {
      //         key: 'g' + item.id,
      //         label: item.name,
      //         leaf: true,
      //         icon: 'pi pi-users',
      //         data: item,
      //         id: item.id,
      //         selectable: true,
      //         weight: 50,
      //         type: 'group'
      //       }
      //     }, this))
      //     org.children.sort(this.sort)
      //     this.$forceUpdate()
      //   }).catch((error) => {
      //     let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
      //     this.$root.showMessage('Unable to get participants' + (data.reason ? ': ' + data.reason : ''), 'error')
      //   }).then(() => {
      //     this.loadingGroups = false
      //   })
      // }
    },
    nodeExpand(node) {
      if (!node.leaf) {
        this.loadOrg(node)
      }
    },
    nodeSelect() {
      this.selectParticipants()
    },
    nodeUnselect() {
      this.selectParticipants()
    },
    initRootOrg() {
      getList('ORGANIZATION', 0, '', 10000, localStorage.getItem('orgId'), false).then((response) => {
        this.organizations = response.data.list.map((item) => {
          this.fetched['o' + item.id] = item
          return {
            key: 'o' + item.id,
            label: item.name,
            leaf: false,
            children: [],
            icon: 'pi pi-sitemap',
            data: item,
            selectable: false,
            weight: 100,
            type: 'organization'
          }
        }, this)

        this.organizations.forEach((org) => {
          this.loadOrg(org)
        })
      }).catch((error) => {
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage('Unable to get organization' + (data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
    selectParticipants() {
      let members = {}
      let orderBase = this.modelValue.length
      this.modelValue?.forEach((item) => {
        if (item.participantId) {
          if (this.selectedItems['p' + item.participantId]) {
            members['p' + item.participantId] = item
          }
        }
        if (item.participantGroupId) {
          if (this.selectedItems['g' + item.participantGroupId]) {
            members['g' + item.participantGroupId] = item
          }
        }
      }, this)
      Object.keys(this.selectedItems).forEach((item) => {
        if (!item.startsWith('o') && !members[item]) {
          let member = {
            id: '_' + item,
            participantId: null,
            participantGroupId: null,
            orderId: orderBase + Object.keys(members).length
          }
          member[this.groupIdField] = !this.groupId || this.groupId.toString().startsWith('_') ? null : this.groupId

          if (item.startsWith('p')) {
            member['participantId'] = this.fetched[item].id
            member['title'] = this.fetched[item].name + ' ' + this.fetched[item].surname
            member['name'] = this.fetched[item].name + ' ' + this.fetched[item].surname
            member['shortName'] = this.fetched[item].shortName
          }
          if (item.startsWith('g')) {
            member['participantGroupId'] = this.fetched[item].id
            member['title'] = this.fetched[item].name
            member['name'] = this.fetched[item].name
          }

          members[item] = member
        }
      }, this)
      this.$emit('update:modelValue', Object.values(members))
    },
    initSelected() {
      this.modelValue?.forEach((item) => {
        if (item.participantId) {
          this.selectedItems['p' + item.participantId] = {checked: true, partialChecked: false}
        }
        if (item.participantGroupId) {
          this.selectedItems['g' + item.participantGroupId] = {checked: true, partialChecked: false}
        }
        if (item.orgId) {
          this.selectedItems['o' + item.orgId] = {checked: false, partialChecked: true}
        }
      }, this)
    },
    sort(a, b) {
      if (a.weight == b.weight) {
        return a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
      } else {
        return a.weight < b.weight ? -1 : 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep(.p-accordion-toggle-icon) {
  padding-left: 10px;
}
</style>
